<template>
  <div>
    <div class="container" v-if="admin_access">
      <span class="cct-button" @click="display_tab = 'raffles'">Raffles</span>
      <span class="cct-button" @click="display_tab = 'discount'"
        >Discount codes</span
      >
      <span class="cct-button" @click="test()">Test</span>

      <br />
      <br />
      <br />

      <!-- RAFFLES -->
      <div v-show="display_tab == 'raffles'">
        <span class="cct-button" @click="new_raffle_is_on = true"
          >New raffle</span
        >
        <br />
        <br />
        <br />

        <div v-if="new_raffle_is_on === true">
          <table class="cct_admin_table">
            <tr>
              <td>Name:</td>
              <td><input type="text" v-model="new_raffle.name" /></td>
            </tr>
            <tr>
              <td>Image:</td>
              <td><input type="text" v-model="new_raffle.image" /></td>
            </tr>
            <tr>
              <td>End date:</td>
              <td>
                <input type="datetime-local" v-model="new_raffle.end_date" />
              </td>
            </tr>
            <tr>
              <td>Ticket price:</td>
              <td><input type="text" v-model="new_raffle.ticket_price" /></td>
            </tr>
            <tr>
              <td>Tickets available:</td>
              <td><input type="text" v-model="new_raffle.max_tickets" /></td>
            </tr>
            <tr>
              <td>Winners:</td>
              <td><input type="text" v-model="new_raffle.winners" /></td>
            </tr>
            <tr>
              <td>Discord:</td>
              <td><input type="text" v-model="new_raffle.discord" /></td>
            </tr>
            <tr>
              <td>Twitter:</td>
              <td><input type="text" v-model="new_raffle.twitter" /></td>
            </tr>
            <tr>
              <td>Website:</td>
              <td><input type="text" v-model="new_raffle.website" /></td>
            </tr>
            <tr>
              <td>Raffle type:</td>
              <td>
                <select v-model="new_raffle.raffle_type">
                  <option value="nft">NFT</option>
                  <option value="wl">WL</option>
                </select>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <span class="cct-button" @click="save_new_raffle()">Save</span>
              </td>
            </tr>
          </table>

          <br />
          <br />
          <br />
        </div>

        <table class="cct_admin_table">
          <tr class="cct_admin_table_title">
            <td>Item</td>
            <td>End date</td>
            <td>Tickets sold</td>
            <td>Winners</td>
            <td>Actions</td>
          </tr>
          <tr v-for="(raffle, key) in raffles" :key="key">
            <td><img :src="raffle.image" style="width: 100px" /></td>
            <td>{{ raffle.end_date }}</td>
            <td>{{ raffle.tickets_sold }}</td>
            <td>
              <ul v-if="raffle.raffle_winners !== null">
                <li v-for="(winner, key) in raffle.raffle_winners" :key="key">
                  {{ winner.wallet }} ({{ winner.entries }} entries, discord:
                  {{ winner.discord }}, twitter: {{ winner.twitter }})
                </li>
              </ul>
            </td>
            <td>
              <span
                class="cct-button"
                @click="draw_winner(raffle.id)"
                v-if="
                  raffle.raffle_winners === null && raffle.time_left != 'closed'
                "
                >DRAW</span
              >
              <span
                class="cct-button"
                @click="edit_raffle(raffle)"
                v-if="raffle.time_left != 'closed'"
                >EDIT</span
              >
              <span class="cct-button" @click="delete_raffle(raffle.id)"
                >DELETE</span
              >
            </td>
          </tr>
        </table>
      </div>

      <!-- DISCOUNT CODES -->
      <div v-show="display_tab == 'discount'">
        <span class="cct-button" @click="new_discount_is_on = true"
          >New discount code</span
        >
        <br />
        <br />
        <br />

        <div v-if="new_discount_is_on === true">
          <table class="cct_admin_table">
            <tr>
              <td>Name:</td>
              <td><input type="text" v-model="new_discount.name" /></td>
            </tr>
            <tr>
              <td>Category:</td>
              <td>
                <select v-model="new_discount.category">
                  <option value="1">Tees</option>
                  <option value="2">Hats</option>
                  <option value="3">Hoodies</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Price rule ID:</td>
              <td>
                <input type="text" v-model="new_discount.price_rule_id" />
              </td>
            </tr>
            <tr>
              <td>Price (aqua):</td>
              <td><input type="text" v-model="new_discount.aqua" /></td>
            </tr>
            <tr>
              <td>Discount (%):</td>
              <td><input type="text" v-model="new_discount.discount" /></td>
            </tr>

            <tr>
              <td></td>
              <td>
                <span class="cct-button" @click="save_new_discount()"
                  >Save</span
                >
              </td>
            </tr>
          </table>

          <br />
          <br />
          <br />
        </div>

        <table class="cct_admin_table">
          <tr class="cct_admin_table_title">
            <td>Item category</td>
            <td>Price rule ID</td>
            <td>Item name</td>
            <td>AQUA price</td>
            <td>Discount</td>
            <td>Actions</td>
          </tr>
          <tr v-for="(discount, key) in discounts" :key="key">
            <td>
              <span v-if="discount.category == 1">Tees</span>
              <span v-if="discount.category == 2">Hats</span>
              <span v-if="discount.category == 3">Hoodies</span>
            </td>
            <td>{{ discount.price_rule_id }}</td>
            <td>{{ discount.name }}</td>
            <td>{{ discount.aqua }}</td>
            <td>{{ discount.discount }}</td>
            <td>
              <span class="cct-button" @click="delete_discount(discount.id)"
                >DELETE</span
              >
            </td>
          </tr>
        </table>

        <br />
        <br />
        <br />

        <table class="cct_admin_table">
          <tr class="cct_admin_table_title">
            <td>Discount code</td>
            <td>Code</td>
            <td>Wallet</td>
            <td>Signature</td>
            <td>Confirmed</td>
            <td>Date</td>
          </tr>
          <tr v-for="(discount, key) in discounts_users" :key="key">
            <td>{{ discount.name }}</td>
            <td>{{ discount.code }}</td>
            <td>{{ discount.wallet_address }}</td>
            <td>{{ discount.signature }}</td>
            <td>{{ discount.confirmed }}</td>
            <td>{{ discount.date }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import {
  check_for_transaction_confirmation,
  create_discount_program,
  fetch_escrow_data,
} from "@/libs/rustProgram";
import { getWalletAddress, getSolanaObject } from "@/libs/wallet";

let config_axios = {
  headers: {
    "Content-Type": "application/json;",
  },
};

let backend_url = "https://cct.effe2.xyz/";

export default {
  name: "Admin",
  data: function () {
    return {
      display_tab: "raffles",
      admin_access: false,
      // discounts: {},
      discounts: [],
      discounts_users: [],
      raffles: {},

      new_raffle: {},
      new_discount: { category: 1 },
      new_raffle_is_on: false,
      new_discount_is_on: false,
    };
  },
  mounted: function () {
    var component = this;

    component.$root.$emit("display_connection_modal");

    component.$root.$on("wallet_connexion_success", function () {
      var accept_wallet = [
        "h9eQLMTZe3Udm611gRB28euyXxHbh3r66dvjskfg33f",
        "43DD2MMQJ6d6sqRzPy5aFBp1fTuT1S4Jvhwpts6rcwda",
        "EARJ745DHX2BD6WamTNDACc5mDoAayMkytuCjqfAZqNa",
        "FKwFeCynWZz8dNDAHTVQtnNXZGEF2YPVbSdrb3Rjs6LQ",
        "Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH",
        "gcMfSkVZQDHvEXA799GccjJHHCTJmLtpFjZKp4ocPNW",
      ];

      if (accept_wallet.includes(this.$root.wallet_address)) {
        component.admin_access = true;
      } else {
        component.$router.push("/");
      }
    });

    // get live raffle
    axios
      .get("https://marketplace.bluediamonds.app/cct/get_all_raffles")
      .then(function (result) {
        component.raffles = result.data.raffles;
      });

    // get discount codes
    axios.get(backend_url + "shop/discount/get").then(function (result) {
      component.discounts = result.data.response.discounts;
    });

    // get discount codes (bought)
    axios.get(backend_url + "shop/discount-user/get").then(function (result) {
      component.discounts_users = result.data.response.discounts;
    });
  },

  methods: {
    refresh_wallet: function () {
      if (!window.solana) return false;

      window.solana.connect();

      if (!window.solana.publicKey) return false;

      if (
        window.solana.publicKey.toString() ==
          "Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH" ||
        window.solana.publicKey.toString() ==
          "4s71pHWJet6cXRsp21v57FF7R4sWaXepDRaNqxCEvDDe"
      ) {
        this.admin_access = true;
      }
    },

    draw_winner: function (raffle_id) {
      var component = this;

      axios
        .get("https://marketplace.bluediamonds.app/cct/draw/" + raffle_id)
        .then(function (result) {
          component.raffles = result.data.raffles;
        });
    },

    edit_raffle: function (raffle) {
      raffle.end_date = raffle.end_date.replace(" ", "T");

      this.new_raffle_is_on = true;
      this.new_raffle = raffle;
    },

    delete_raffle: function (raffle_id) {
      if (!window.confirm("Are you sure ? This is definitive !")) return;

      var component = this;

      axios
        .get(
          "https://marketplace.bluediamonds.app/cct/delete_raffle/" + raffle_id
        )
        .then(function (result) {
          component.raffles = result.data.raffles;
        });
    },

    save_new_raffle: function () {
      var component = this;

      var data = this.new_raffle;

      axios
        .post("https://marketplace.bluediamonds.app/cct/new_raffle", data)
        .then(function (result) {
          component.raffles = result.data.raffles;

          component.new_raffle_is_on = false;
          component.new_raffle = {};
        });
    },

    save_new_discount: async function () {
      var component = this;

      var data = this.new_discount;

      let aqua = data.aqua * 1000000000;
      let discount = data.discount;
      let product = data.id;

      var [signature, escrow] = await create_discount_program(
        getSolanaObject(),
        getWalletAddress(),
        aqua,
        discount,
        product
      );

      data.escrow_account = escrow.toString();

      axios
        .post(backend_url + "shop/discount/create", data)
        .then(function (result) {
          component.discounts = result.data.response.discounts;

          component.new_discount_is_on = false;
          component.new_discount = { category: 1 };
        });
    },

    delete_discount: function (discount_id) {
      if (!window.confirm("Are you sure ? This is definitive !")) return;

      var component = this;

      axios
        .get(backend_url + "shop/discount/delete/" + discount_id)
        .then(function (result) {
          component.discounts = result.data.discounts;
        });
    },
  },
};
</script>

<style scoped></style>
